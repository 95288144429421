import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { InfoBox } from "./outsideClick/OutClick";
import { useLocation } from 'react-router-dom';

import headerLogo from "../../assets/images/header-img/headerLogo.png";
import alert from "../../assets/images/header-img/alert.png";
import "./style.scss";

const TestNavBar = () => {
  const location = useLocation();
  let [showInfo1, setShowInfo1] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleRemove = () => {
    let element = document.getElementById("home");
    element.classList.remove("active");
  }

  const [isOpen, setIsOpen] = useState(false)

  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    setIsClicked(true)
    setTimeout(() => {
      setIsClicked(false);
    },2000);
  }

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    console.log(location, "location")
  },[location])

  return(
    <header className={`test-header-section ${isScrolled ? 'scrolled' : ''}`}>
      <div className={'inner-section-content'}>
        {
          isClicked &&
          <div className="ouch">
            <img className="alert-img" src={alert}/>
            <p>
              Ouch!That Hurts!
            </p>
          </div>
        }
        <div className="logo" onClick={handleClick}>
          <img src={headerLogo} />
          <p>Empire
            Client
            Generator</p>
        </div>
        <div className="test-header-panel" onClick={handleRemove}>
          {
            [
              {title:"HOME", path:"/"},
              {title:"MARKETING", path:"/marketing"},
              {title:"PUBLIC RELATIONS", path:"/publicrelations"},
              {title:"BRANDING", path:"/brand"},
              {title:"WEB3 MARKETING", path:"/webmarketing"},
              {title:"DEVELOPMENT", path:"/appdev"}
            ].map(item=>
              <div onClick={() => {setShowInfo1(true)}}>
                <Link to={item.path}
                      className={item.path === location.pathname ? "active" : null}
                      id={item.path === location.pathname ? "home" : null}
                ><span>{item.title}</span></Link>
              </div>
            )
          }
        </div>
      </div>
      <InfoBox show={showInfo1} onClickOutside={() => {setShowInfo1(false)}} style={{width:"0px", height:"0px"}}/>
    </header>
  )
}
export default TestNavBar;
